// AplineJS
import 'alpinejs';
const $ = require('jquery');
const axios = require('axios');

// Include styling
import '../scss/tailwind.scss';
import '../scss/app.scss';

/**
 * Login page
 */
$('#page_login form').on('submit', function(e) {
    e.preventDefault();
    axios.post('/api/login/', {
        'email': document.getElementById('email').value,
        'password': document.getElementById('password').value
    })
        .then(function (response) {
            if(response.data.data.status < 1) {
                $('#errorMsg').fadeIn();
            } else {
                window.location.href = "/";
            }
        })
        .catch(function (error) {
            console.log(error);
        })
});